var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageTitle",
        [
          _c("PlayerCell", {
            attrs: { image: _vm.team_image, name: _vm.player_name }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "container-fluid d-flex align-items-stretch h-100 flex-fill"
        },
        [
          _c(
            "aside",
            { staticClass: "d-flex flex-column" },
            [
              _c("TeamHeader", {
                attrs: { image: _vm.team_image, player_name: _vm.player_name }
              }),
              _c("MapNavigation", {
                on: {
                  select_map: function($event) {
                    return _vm.select_map($event)
                  }
                }
              })
            ],
            1
          ),
          _vm.loading_info
            ? _c(
                "main",
                {
                  staticClass:
                    "flex-fill d-flex justify-content-center mt-4 mb-4"
                },
                [_vm._v("Loading")]
              )
            : _c(
                "main",
                { staticClass: "flex-fill mt-4 mb-4" },
                [
                  _vm.server_error
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(" " + _vm._s(_vm.server_error) + " ")
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "team-headline d-flex justify-content-between align-items-end mb-5"
                    },
                    [
                      _c("div", [
                        _c(
                          "h1",
                          [
                            _vm._v(" " + _vm._s(_vm.player_name) + " "),
                            _c(
                              "router-link",
                              {
                                staticClass: "small",
                                attrs: {
                                  to:
                                    "/team/" +
                                    _vm.team_id +
                                    "/stats/" +
                                    _vm.$route.params.map_id
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.team_name) + " ")]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm.selected_map_id
                        ? _c(
                            "b-nav",
                            { attrs: { pills: "", align: "center" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "outline-primary",
                                    active: _vm.$route.name === "PlayerStats",
                                    to:
                                      "/player/" +
                                      _vm.$route.params.player_id +
                                      "/stats/" +
                                      _vm.selected_map_id
                                  }
                                },
                                [_vm._v(" Stats ")]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "outline-primary",
                                    active: _vm.$route.name === "PlayerMatches",
                                    to:
                                      "/player/" +
                                      _vm.$route.params.player_id +
                                      "/matches/" +
                                      _vm.selected_map_id +
                                      "/1/10"
                                  }
                                },
                                [_vm._v(" Matches ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("router-view", { attrs: { name: "player" } })
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }